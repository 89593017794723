<template>
  <input-field-list :title="$t('infoAboutInsuredProperty')">
   <!--  <search-select
        label="title"
        name="insured_country"
        rules="required"
        :options="countries"
        v-model="form.country"
        :reduce="item => item.value"
        :placeholder="$t('country')"
        :is-block="false"
    /> -->
    <search-select
        label="title"
        name="insured_state"
        rules="required"
        :options="currentStatesList"
        v-model="form.state"
        :placeholder="$t('region')"
        :reduce="item => item.value"
        :searchable="currentStatesCount > 1"
        :no-drop="currentStatesCount <= 1"
        :is-block="false"
        @optionSelected="optionSelected"
        ref="stateSelect"
    />
    <search-select
        v-if="!showRegion.includes(form.state)"
        label="title"
        :options="currentRegionsList"
        name="insured_region"
        rules="required"
        v-model="form.region"
        :placeholder="$t('district')"
        :reduce="item => item.value"
        :searchable="currentRegionsCount > 1"
        :no-drop="currentRegionsCount <= 1"
        :is-block="false"
        @optionSelected="optionSelected"
        ref="regionSelect"

    />
    <search-select
        v-if="!publicRegions.includes(form.state)"
        :options="currentCitiesList"
        label="title"
        :is-block="false"
        track-by="value"
        name="insured_city"
        rules="required"
        v-model="form.city"
        :reduce="item => item.value"
        :searchable="currentCitiesCount > 1"
        :no-drop="currentCitiesCount <= 1"
        :placeholder="$t('city')"
        @optionSelected="optionSelected"
        ref="citySelect"
    />

    <input-field
        name="insured_street_name"
        rules="required"
        :label="$t('street')"
        v-model="form.street_name"
    />

    <input-field
        name="insured_street_no"
        rules="required"
        :label="$t('building')"
        v-model="form.street_no"
    />

    <input-field
        v-if="needFlat"
        name="insured_flat_no"
        :label="$t('apartment')"
        v-model="form.flat_no"
    />
  </input-field-list>
</template>

<script>
import InputFieldList from "./BuyPolicy/InputFieldList";
import SearchSelect from "./BuyPolicy/SearchSelect";
import InputField from "./BuyPolicy/InputField";
import filterOptionsList from "../utils/filterOptionsList";
import { mapGetters } from "vuex";

export default {
  components: {
    InputFieldList,
    SearchSelect,
    InputField
  },
  props: {
    value: Object,
    canDelete: {
      type: Boolean,
      default: false
    },
    needFlat: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      form: {
        country: "KAZ",
        state: null,
        region: null,
        city: null,
        street_name: null,
        street_no: null,
        flat_no: null,
      },
      showRegion: ['SHM', "AST"],
      publicRegions: ["ALM", "NUR", "SHM", "AST"],
      regionDropdownShouldOpen: false,
      cityDropdownShouldOpen: false
    }
  },
  created() {
    this.initData(this.value)
  },
  computed: {
    ...mapGetters({
      countries: 'dictionary/countries',
      states: 'dictionary/states',
      countryStates: 'dictionary/countryStates',
      regions: 'dictionary/regions',
      stateRegions: 'dictionary/stateRegions',
      cities: 'dictionary/cities',
      regionCities: 'dictionary/regionCities'
    }),
    currentStatesList() {
      return filterOptionsList(this.states, this.countryStates, this.form.country);
    },
    currentRegionsList() {
      switch (this.form.state) {
        case "AST":
          return [{"value": "6","title":"Астана"}]
        case "NUR":
          return [{"value": "685","title":"Нур-Султан"}]
        case "SHM":
          return [{"value": "862","title":"Шымкент"}]
        default:
          return filterOptionsList(this.regions, this.stateRegions, this.form.state);
      }
    },
    currentCitiesList() {
      if(this.form.region === '6877') {
         return [{'value': "6939", "title": "Алматы"}]
      }
      switch (this.form.state) {
        case "AST":
          return [{"value": "273", "title": "Астана"}]
        default:
          return filterOptionsList(this.cities, this.regionCities, this.form.region);
      }
    },
    currentStatesCount () {
      return this.currentStatesList?.length
    },
    currentRegionsCount() {
      return this.currentRegionsList?.length
    },
    currentCitiesCount() {
      return this.currentCitiesList?.length
    }
  },
  methods: {
    initData(property) {
      this.form.country = property?.country;
      this.form.state = property?.state;
      this.form.region = property?.region;
      this.form.city = property?.city;
      this.form.street_name = property?.street_name;
      this.form.street_no = property?.street_no
      this.form.flat_no = property?.flat_no
    },
    optionSelected(payload) {
      if(payload === 'insured_state') {
        if(this.$refs?.regionSelect?.$refs?.vSelectComponent) {
          this.$refs.regionSelect.$refs.vSelectComponent.open = true;
        } else if(this.showRegion.includes(this.form.state)) {
          this.$emit('toDateField')
        }
      }

      if(payload === 'insured_region' && this.$refs?.citySelect?.$refs?.vSelectComponent) {
        this.$refs.citySelect.$refs.vSelectComponent.open = true;
      }

      if(payload === 'insured_city' && this.$refs?.citySelect?.$refs?.vSelectComponent) {
        this.$emit('toDateField')
      } else {
        if(this.publicRegions.includes(this.form.state)) {
          this.$emit('toDateField')
        }
      }


    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.initData(newValue)
      }
    },
    form: {
      deep: true,
      handler (changed) {
        this.$emit('changed', changed)
      }
    },
    currentStatesList: {
      handler() {
        if (this.currentStatesList && this.currentStatesList.length === 1) {
          this.form.state = this.currentStatesList[0].value
        } else {
          if(!this.currentStatesList.some(item => String(item.value) === String(this.form.state))) {
            this.form.state = null
          }
        }
      }
    },
    currentRegionsList: {
      handler() {
        if (this.currentRegionsList && this.currentRegionsList.length === 1) {
          this.form.region = this.currentRegionsList[0].value
        } else {
          if(!this.currentRegionsList.some(item => String(item.value) === String(this.form.region))) {
            this.form.region = null
          }
        }
      }
    },
    currentCitiesList: {
      handler() {
        if (this.currentCitiesList && this.currentCitiesList.length === 1) {
          this.form.city = this.currentCitiesList[0].value
        } else {
          if(!this.currentCitiesList.some(item => String(item.value) === String(this.form.city))) {
            this.form.city = null
          }
        }
      }
    }
  }
}
</script>
