<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ invalid, errors }">
      <div>
        <input-field
            :label="$t('phoneNumber')"
            v-model="phone_number"
            field-type="tel"
            :is-block="true"
            rules="required|numeric|min:10"
            name="phone"
            mask="+7(###) ###-##-##"
            :disabled="true"
        />
      </div>

        <PersonInfo
            v-if="holder"
            :value="holder"
            @changed="changeSubsHolder"
            :key="holder.uuid"
            @error="onError"
            rules="required"
            :ref="holder.uuid"
        />

        <!-- <radio-input-list
            :read-more="false"
            :options="monthsOptions"
            radio-name="periods"
        /> -->

        <div v-if="holder && holder.individual_id_number && holder.individual_id_number.length === 12">
<!--          <checkbox-list v-if="false" :options="ownerIsHolderProp" name="owner_is_holder" v-model="ownerIsHolder"/>-->

          <PersonInfo
              v-if="false"
              :value="propertyOwner"
              :key="propertyOwner.uuid"
              @changed="changePropertyOwner"
              @error="onError"
              :ref="propertyOwner.uuid"
          />

          <div class="insured-property-select-wrap" v-if="holder.individual_id_number && (ownerIsHolder || (!ownerIsHolder && propertyOwner && propertyOwner.individual_id_number))">
            <InsuredProperty :value="insuredProperty" @changed="changeInsuredProperty"/>

<!--            <select-date-->
<!--               :value="startDate"-->
<!--               @input="changeStartDate"-->
<!--               :title="$t('policyStartDate')"-->
<!--               rules="required"-->
<!--               name="policy_date"-->
<!--               :until="initialDate"-->
<!--               :is-disabled-before-today="true"-->
<!--               :is-block="true"-->
<!--            />-->
          </div>
        </div>

        <!--  <select-rate
          :title="'Тарифы'"
          :rates="rates"
          @change_input="changeRate"/> -->

           <template v-if="loadingActive">
            <div class="image-card card py-4 px-3" v-for="item in 1" :key="item" style="margin-bottom: 16px;">
              <div class="">
                <h3 class="title skeleton skeleton-text w-75"></h3>
                <h3 class="title skeleton skeleton-text w-50"></h3>
                <h3 class="title skeleton skeleton-text w-25"></h3>
                <div class="gray-btn skeleton w-50"></div>
              </div>
            </div>
          </template>
       
        <select-tariff v-else :tariffs="tariffs" :title="$t('rate')" @change_input="changeTariffCalc"></select-tariff>

       
        <div class="final-amount-wrapper" v-if="rates_index != null">
          <final-amount
              :amount="finalAmountObj.amount"
              :discount-value="finalAmountObj.bonus_value"
              :fix-sum="finalAmountObj.fix_sum"
              :rate-total="true"
              :invalid="invalid"
              :is-sub="true"
              @submit="onSubmit"
          />
        </div>
    </ValidationObserver>
  </div>
</template>

<script>
import InputField from "@/components/BuyPolicy/InputField";
import SelectDate from "../BuyPolicy/SelectDate";
import {mapActions, mapGetters} from "vuex";
import CheckboxList from "../BuyPolicy/CheckboxList";
import FinalAmount from "../BuyPolicy/FinalAmount";
import {flatSubscriptionCalculation} from "../../api/calculations";
import ePayPayment from "../../mixin/ePayPayment";
import eventLogger from '../../services/eventLogger';
import PersonInfo from "../PersonInfo";
import RadioInputList from "../BuyPolicy/RadioInputList";
import InsuredProperty from "../InsuredProperty";
import moment from "moment";
import {uuidv4} from "../../plugins/Helpers";
import commonFormData from "../../mixin/commonFormData";
import redmine from "../../mixin/redmine";
import BoxProductBonuses from "../BuyPolicy/BoxProductBonuses";
import SelectRate from "../BuyPolicy/SelectRate";
import SelectTariff from "@/components/BuyPolicy/SelectTariff";
import subscriptionForm from "../../mixin/subscriptionForm";

export default {
  name: "FlatSubscriptionForm",
  mixins: [ePayPayment, commonFormData, redmine, subscriptionForm],
  components: {
    BoxProductBonuses,
    InsuredProperty,
    PersonInfo,
    CheckboxList,
    InputField,
    SelectDate,
    FinalAmount,
    RadioInputList,
    SelectRate,
    SelectTariff
  },
  mounted(){
    //this.changeRate(this.rates_index);
    this.getFullTariff();
  },
  data() {
    return {
      tariffUrl: 'subscription/apartment-tariffs',
      loadingActive: true,
      currentTariff: 'Легкая',
      tariffs: [],
      rates_index: 0,
      rates: [
        {
          name: 'Легкий', price: 490, 
          lists: [
            { name: 'Выплата Вам ', price: '500 000' }, 
            { name: 'Выплата соседям ', price: '500 000' }, 
            { name: 'Возместим итого  ', price: '1 000 000' }, 
          ] 
        },
        {
          name: 'Стандартный', 
          price: 890, 
          lists: [
            { name: 'Выплата Вам', price: '1 000 000' },
            { name: 'Выплата соседям', price: '1 000 000' },
            { name: 'Возместим итого', price: '2 000 000' },
          ] 
        },
        { name: 'Оптимальный', 
          price: 1290, 
          lists: [
            { name: 'Выплата Вам', price: '1 500 000'},
            { name: 'Выплата соседям', price: '1 500 000'},
            { name: 'Возместим итого', price: '3 000 000'},
          ] 
        },
      ],
      rates_calc: [
        {amount: 1470, bonus_title: 'Скидка на первые три месяца ', bonus_value: 1200, total_title: 'Итого  на три месяца',fix_sum: 270, product_name: 'за три месяца', label_id: 'LIGHT' },
        {amount: 2670, bonus_title: 'Скидка на первые три месяца ', bonus_value: 2100, total_title: 'Итого  на три месяца',fix_sum: 570, product_name: 'за три месяца' , label_id: 'STANDARD'  },
        {amount: 3870, bonus_title: 'Скидка на первые три месяца  ', bonus_value: 3000, total_title: 'Итого  на три месяца',fix_sum: 870, product_name: 'за три месяца',  label_id: 'OPTY'  },
      ],
      finalAmountObj:{},
      amount: 12000,
      monthsOptions: [{ name: this.$i18n.t('month12'), value: 12 }],
      ownerIsHolderProp: [
        {
          name: this.$i18n.t('insuredAndPolicyHolderMatch'),
          value: this.ownerIsHolder,
        }
      ],
    }
  },
  async created() {
    eventLogger('view_apartment_insurance_subscription');

    this.changeManId(this.authUser.id);

    const { getUnionAddressDictionary, $loading } = this;

    const loader = $loading.show();

    try{
      await getUnionAddressDictionary();
    } catch (err) {
      this.onError({
        title: `Ошибка при получение данных справочника`,
        message: (err?.response?.data?.message || err?.message),
        code: err?.response?.data?.error_code || 0
      })
    }

    this.changeTariff('Легкий');
    
    loader.hide()
  },
  computed: {
    ...mapGetters({
      authUser: 'authUser',
      holder: 'flatSubs/holder',
      propertyOwner: 'flatSubs/propertyOwner',
      insuredProperty: 'flatSubs/insuredProperty',
      startDate: "flatSubs/startDate",
      form: 'flatSubs/finalSubscriptionFormValue',
      manId: 'flatSubs/manId',
    }),
    ownerIsHolder: {
      get() {
        return this.$store.getters['flatSubs/ownerIsHolder'];
      },
      set(val) {
        this.changeOwnerIsHolder(val);
      }
    },
    finalAmount() {
      return this.amount
    },
    
    clientsChecked() {
      let checked = true;

      if(this.holder.checked === false) {
        checked = false
      }

      if(this.ownerIsHolder === false && this.propertyOwner.checked === false) {
        checked = false
      }

      return checked
    }
  },
  methods: {
    ...mapActions({
      changeStartDate: 'flatSubs/changeStartDate',
      changeOwnerIsHolder: 'flatSubs/changeOwnerIsHolder',
      changeHolder: 'flatSubs/changeHolder',
      changePropertyOwner: 'flatSubs/changePropertyOwner',
      changeInsuredProperty: 'flatSubs/changeInsuredProperty',
      resetForm: 'flatSubs/resetForm',
      getUnionAddressDictionary: 'dictionary/getUnionAddressDictionary',
      changeSelectedBonus: 'flatSubs/changeSelectedBonus',
      changeTariff: 'flatSubs/changeTariff',
      changeManId: 'flatSubs/changeManId',
    }),


    async calculation() {
      const data =  await flatSubscriptionCalculation(this.form)

      // Логирование
      await eventLogger('select_payment_apartment_subscription', { payment_sum: this.amount })

      return data;
    },

    async setDefaultInfo() {
      const { authUser, holder } = this;

      // Если нет держателя то записываем текущего пользователя
      if(holder === null || !this.holder.individual_id_number) {
        this.changeHolder({ item: { uuid: uuidv4(),individual_id_number: authUser.individual_id_number }})
      }

      this.setInitialDate(moment().add(1, "days").format("YYYY-MM-DD"))
    },

    async setAgreementInfo() {
      this.agreement_id = this.$route.query.agreement_id;

      const { data } = await window.axios.get(`policies/${this.agreement_id}`);

      const object = data?.objects[0];

      await this.$store.commit("flatSubs/SET_HOLDER", {
        uuid: uuidv4(),
        individual_id_number: data.holder.bin
      });

      this.setInitialDate(data.endDate.date)

      if(object) {
        const foundCity = this.listCities.find(item => item.city === object.city);

        if (foundCity) {
          this.changeInsuredProperty({
            country: "KAZ",
            state: String(foundCity?.state),
            region: String(foundCity?.region),
            city: String(foundCity?.city),
            street_name: object.street_name,
            street_no: object.blockNumber,
            flat_no: object.apartmentNumber,
          })
        }
      }
    },

    async checkClients() {
      this.$store.commit('flatSubs/SET_HOLDER_CHECK_MESSAGE', null);

      try {
        await window.axios.post(`/verified-client-csdb`, this.holder)

        this.$store.commit('flatSubs/SET_CHECK_HOLDER', true);
      } catch (e) {
        const error = {
          message: e?.response?.data?.message || 'Ошибка при сверке клиента',
          code: e?.response?.data?.error_code || 0,
          title: e?.response?.data?.message || 'Ошибка при сверке клиента',
        }
        this.onError(error)
        this.$store.commit('flatSubs/SET_CHECK_HOLDER', false);
        this.$store.commit('flatSubs/SET_HOLDER_CHECK_MESSAGE', e?.response?.data?.message);
        this.$refs[this.holder.uuid].scrollToThis();
      }

      if(!this.ownerIsHolder) {
        this.$store.commit('flatSubs/SET_OWNER_CHECK_MESSAGE',null);

        try {
          await window.axios.post(`/verified-client-csdb`, this.propertyOwner)

          this.$store.commit('flatSubs/SET_CHECK_OWNER', true);
        } catch (e) {
          const error = {
            message: e?.response?.data?.message || 'Ошибка при сверке клиента',
            code: e?.response?.data?.error_code || 0,
            title: e?.response?.data?.message || 'Ошибка при сверке клиента',
          }
          this.onError(error)
          this.$store.commit('flatSubs/SET_CHECK_OWNER', false);
          this.$store.commit('flatSubs/SET_OWNER_CHECK_MESSAGE', e?.response?.data?.message);
        }
      }
    }
  },
  watch: {
    ownerIsHolder: {
      handler(isMatch) {
        if(!isMatch) {
          const item = { uuid: uuidv4() }
          this.changePropertyOwner({ item })
        } else {
          this.changePropertyOwner( { item: null })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
