<template>
  <div class="primary-block" v-if="cascoEnabled">
    <h3 class="input-list-title">{{ title }}</h3>
    <ul class="input-list">
      <li class="input-list-item">
        <label class="primary-text label" for="cascoCheck">
          {{$t('addCascoBoth')}}
          <span class="success-text" @click="openModal">{{$t('details')}}</span>
        </label>
        <input v-model="cascoSelected" type="checkbox" name="cascoCheck" />
      </li>
      <li class="input-list-item">
        <label for="cascoReward" class="primary-text label">
          Премия
        </label>
        <select
          v-model="cascoReward"
          name="cascoReward"
          id="cascoReward"
        >
          <option
            :disabled="!cascoSelected"
            v-for="option in cascoOptions"
            :key="option.id"
            :value="option.cost"
          >
            {{ option.cost }}
          </option>
        </select>
      </li>
    </ul>
    <p class="addition-text">
      Выберите стоимость полиса, приемлемую для Вас
    </p>
    <p class="addition-text" v-if="cascoModel">
      {{$t('insuranceLimit')}} <span class="green-text">{{cascoModel.paymentAmount}} ₸.</span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CascoLiteModal from "../Modals/CascoLiteModal";
export default {
  name: "AddCascoLite",
  props: {
    title: {
      type: String,
    },
  },
  computed:{
    ...mapGetters({
      cascoOptions: 'ogpoForm/cascoOptions'
    }),
    cascoEnabled(){
      const {cascoOptions} = this;
      return cascoOptions && cascoOptions.length > 0;
    },
    cascoModel() {
      const { cascoOptions, cascoReward } = this
      if (!cascoOptions) { return }
      let model = cascoOptions.find(item => item.cost == cascoReward)
      if(!model) { return cascoOptions[0] }
      return model
    },
    cascoSelected: {
      get(){
        return this.$store.getters['ogpoForm/cascoSelected']
      }, 
      set(value) {
        let reward = 0;
        const { cascoOptions } = this
       
        if( value ) {
          reward = cascoOptions[0].cost;
        }

        this.cascoReward = reward;

        this.$store.commit('ogpoForm/SET_CASCO_LITE_SELECTED', value)
      }
    },
    cascoReward: {
      get(){
        if(!this.cascoSelected) {
          return 6000;
        }

        return this.$store.getters['ogpoForm/cascoReward']
      }, 
      set(value) {
        this.$store.commit('ogpoForm/SET_CASCO_LITE_REWARD', value)
      }
    }
  },
  methods: {
    openModal() {
      this.$modal.show(CascoLiteModal, {},this.$modalConfig)
    }
  },
};
</script>
