<template>
  <ValidationObserver ref="form" v-slot="{ invalid, errors }">
    <input-field
        :label="$t('enterPhoneNumber')"
        v-model="phone_number"
        field-type="tel"
        :is-block="true"
        rules="required|min:10"
        name="phone"
        mask="+7 (###) ###-##-##"
        :addition="$t('cantChangeNumber')"
        :disabled="true"
    />

    <div v-show="phone_number && errors.phone && errors.phone.length === 0">
      <select-date
          :is-block="true"
          rules="required"
          name="start_date"
          :value="startDate"
          @input="changeStartDate"
          :title="$t('policyStartDate')"
          :is-disabled-before-today="true"
      />

      <PersonInfo
          v-if="holder"
          :value="holder"
          @changed="changeHolder"
          @error="onError"
          :key="holder.uuid"
          rules="required"
          :age-restriction-min="1"
          :age-restriction-max="63"
      />

<!--      <single-check-box :label="$t('policyHolderTheInsured')" v-model="ownerIsHolder"/>-->
    </div>

    <template v-for="(insured, index) in insureds">
      <PersonInfo
          :value="insured"
          :index="index"
          :key="`insured_${insured.uuid}`"
          :can-delete="!!insureds.length"
          @changed="changeInsured"
          @removeItem="removeInsured"
          @error="onError"
          :age-restriction-min="1"
          :age-restriction-max="63"
      />
    </template>

    <add-person-button v-if="actualInsuredsCount < 4" @click.native="addNewInsured"/>
    <box-product-bonuses :available-products="availableBonuses" v-model="bonusProduct"/>
    <div class="final-amount-wrapper">
      <final-amount :bonus-title="bonusTitle"
                    :bonus-value="bonusValue"
                    :amount="finalAmount" @submit="onSubmit" :invalid="invalid" :fix-sum="finalAmount"/>
    </div>
  </ValidationObserver>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {mostImportantCalculation} from "../../api/calculations";
import InputField from "@/components/BuyPolicy/InputField";
import SelectDate from "../BuyPolicy/SelectDate";
import AddPersonButton from "../BuyPolicy/AddPersonButton";
import FinalAmount from "../BuyPolicy/FinalAmount";
import eventLogger from '../../services/eventLogger';
import PersonInfo from "../PersonInfo";
import ePayPayment from "../../mixin/ePayPayment";
import {uuidv4} from "../../plugins/Helpers";
import commonFormData from "../../mixin/commonFormData";
import moment from "moment";
import axios from "axios";
import redmine from "../../mixin/redmine";
import SingleCheckBox from "../BuyPolicy/SingleCheckBox";
import BoxProductBonuses from "../BuyPolicy/BoxProductBonuses";

export default {
  name: "MostImportant",
  mixins: [ePayPayment, commonFormData, redmine],
  components: {
    BoxProductBonuses,
    SingleCheckBox,
    PersonInfo,
    AddPersonButton,
    InputField,
    SelectDate,
    FinalAmount,
  },
  data() {
    return {
      prices: [
        {name: '6000 тенге', reward: 100000, value: 6000},
        {name: '12000 тенге', reward: 200000, value: 12000},
        {name: '18000 тенге', reward: 300000, value: 18000},
        {name: '28000 тенге', reward: 500000, value: 28000}
      ],
      ownerIsHolderOption: {
        name: this.$i18n.t('policyHolderTheInsured'),
      },
      selected_price: null,
      amount: 0,
    }
  },
  watch: {
    ownerIsHolder(value) {
      if (!value && this.insureds.length === 0) {
        this.addNewInsured()
      }
    }
  },
  computed: {
    ...mapGetters({
      holder: 'mostImportantForm/holder',
      startDate: 'mostImportantForm/startDate',
      insureds: 'mostImportantForm/insureds',
      form: 'mostImportantForm/finalFormValue',
    }),
    actualInsuredsCount() {
      const insuredsCount = this.insureds.length;

      return this.ownerIsHolder ? (insuredsCount + 1) : insuredsCount
    },
    ownerIsHolder: {
      get(){
        return this.$store.getters['mostImportantForm/ownerIsHolder']
      },
      set(payload) {
        this.changeOwnerIsHolder(payload)
      }
    },
    clientsChecked(){
      let checked = true;

      if(this.holder.checked === false) {
        checked = false
      }

      this.insureds.forEach(client => {
        if(client.checked === false) { checked = false }
      })

      return checked
    },
    bonusProduct: {
      get() {
        return this.$store.getters['mostImportantForm/bonusId'];
      },
      set(payload) {
        this.changeSelectedBonus(payload)
      }
    },
    finalAmount() {
      return 12000
    },
  },

  async mounted() {
    eventLogger('view_health_insurance')
  },
  methods: {
    ...mapActions({
      changeStartDate: 'mostImportantForm/changeStartDate',
      changeHolder: 'mostImportantForm/changeHolder',
      addNewInsured: 'mostImportantForm/addNewInsured',
      changeInsured: 'mostImportantForm/changeInsured',
      removeInsured: 'mostImportantForm/removeInsured',
      changeOwnerIsHolder: 'mostImportantForm/changeOwnerIsHolder',
      changeSelectedBonus: 'mostImportantForm/changeSelectedBonus'
    }),

    async setDefaultInfo(){
      const { authUser, holder } = this;

      if(holder === null || !this.holder.individual_id_number) {
        this.$store.commit("mostImportantForm/CHANGE_HOLDER", {
          uuid: uuidv4(),
          individual_id_number: authUser.individual_id_number
        })
      }

      this.setInitialDate(moment().add(1, "days").format("YYYY-MM-DD"))
    },

    async setAgreementInfo() {
      this.agreement_id = this.$route.query.agreement_id;

      const { data } = await axios.get(`policies/${this.agreement_id}`);

      this.setInitialDate(data.endDate.date)

      this.$store.commit("mostImportantForm/CHANGE_HOLDER", {
        uuid: uuidv4(),
        individual_id_number: data.holder.bin,
      });

      this.$store.commit('mostImportantForm/RESET_INSUREDS');

      data.insureds
          .filter(item => item.bin !== data.holder.bin)
          .map((item) => {
            const defaultDoc = item.documents.find(doc => doc.doc_type === 'IC') || item.documents[0];
            const defaultAddress = item.addresses.find(address => address.address_type === 'H') || item.addresses[0];
            const docDate =  defaultDoc?.doc_issue_date?.date ? moment(defaultDoc.doc_issue_date.date) : moment();
            this.$store.commit('cascoForm/ADD_INSURED', {
              uuid: uuidv4(),
              individual_id_number: item.bin,
              gender: item.sex,
              doc_type: defaultDoc?.doc_type,
              doc_number: defaultDoc?.doc_number,
              doc_issue_date: docDate.format('YYYY-MM-DD'),
              address_region: defaultAddress?.address_region,
              address_state: defaultAddress?.address_state,
              address_city: defaultAddress?.address_city,
              address_street_full: defaultAddress?.address_street,
              address_street_no: defaultAddress?.address_street_no,
              address_flat_no: defaultAddress?.address_flat_no,
            })
          })
    },
    async calculation() {
      eventLogger('select_payment_health', { payment_sum: this.amount })

      return await mostImportantCalculation(this.form)
    },

    /**
     * Сверка клиентов перед отправкой
     * */
    async checkClients()
    {
      this.$store.commit('mostImportantForm/SET_HOLDER_CHECK_MESSAGE', null)

      try{
        await axios.post(`/verified-client-csdb`, this.holder)

        this.$store.commit('mostImportantForm/SET_CHECK_HOLDER', true)
      } catch (e) {
        const error = {
          message: e?.response?.data?.message || 'Ошибка при сверке клиента',
          code: e?.response?.data?.error_code || 0,
          title: e?.response?.data?.message || 'Ошибка при сверке клиента',
        }
        this.onError(error)
        this.$store.commit('mostImportantForm/SET_CHECK_HOLDER', false)
        this.$store.commit('mostImportantForm/SET_HOLDER_CHECK_MESSAGE', e?.response?.data?.message)
      }

      for (let index = 0; index < this.insureds.length; index++) {
        let item = this.insureds[index];

        item.checkMessage = null

        try {
          await axios.post(`/verified-client-csdb`, this.insureds[index])

          item.checked = true
        }catch (e) {
          const error = {
            message: e?.response?.data?.message || 'Ошибка при сверке клиента',
            code: e?.response?.data?.error_code || 0,
            title: e?.response?.data?.message || 'Ошибка при сверке клиента',
          }
          this.onError(error)
          item.checkMessage = e?.response?.data?.message
          item.checked = false
        }

        this.changeInsured({ item, index })
      }
    }
  },
}
</script>
