import {uuidv4} from "../plugins/Helpers";
import moment from "moment";
import bonuses from "./bonuses";
import {mapGetters} from "vuex";

export default {
  mixins: [bonuses],
  data() {
    return {
      insuredSum: null,
      tourists: [],
      travel_types: [
        { name: this.$i18n.t('singleTravel'), id: false },
        { name: this.$i18n.t('multiTravel'), id: true },
      ],
      single_insurance_periods: [
        {name: this.$i18n.t('numDays30'), value: 30},
        {name: this.$i18n.t('numDays60'), value: 60},
        {name: this.$i18n.t('numDays90'), value: 90},
        {name: this.$i18n.t('numDays120'), value: 120},
        {name: this.$i18n.t('numDays180'), value: 180},
        {name: this.$i18n.t('numDays365'), value: 365},
      ],
      multiple_insurance_periods: [
        {name: this.$i18n.t('month1'), value: 30},
        {name: this.$i18n.t('month3'), value: 90},
        {name: this.$i18n.t('month6'), value: 180},
        {name: this.$i18n.t('year1'), value: 365},
      ],
      sports: [
        {name: this.$i18n.t('sports1'), value: 5},
        {name: this.$i18n.t('sports2'), value: 4},
        {name: this.$i18n.t('sports3'), value: 7},
        {name: this.$i18n.t('sports4'), value: 9},
        {name: this.$i18n.t('sports5'), value: 12},
        {name: this.$i18n.t('sports6'), value: 11},
        {name: this.$i18n.t('sports7'), value: 10},
        {name: this.$i18n.t('sports8'), value: 2},
        {name: this.$i18n.t('sports9'), value: 6},
        {name: this.$i18n.t('sports10'), value: 3},
        {name: this.$i18n.t('sports11'), value: 8},
      ],
      travelDurations: [
        { name: this.$i18n.t('upTo180'), value: 180 },
        { name: this.$i18n.t('upTo45'), value: 45 },
        { name: this.$i18n.t('upTo90'), value: 90 },
      ],
      franchiseOptions: [
        { name: '0%', value: '0%' },
        { name: '30%', value: '30%' }
      ]
    }
  },
  watch: {
    isSchengenArea(isSchengenArea) {
      if(isSchengenArea) {
        // Для шенгена только 1 турист
        if(this.form.owner_is_holder) {
          this.form.insureds.length = 0;
        } else {
          this.form.insureds.length = 1;
        }
      }
    },
    insuredSum: function(selectedSum) {
      this.form.cover_value = selectedSum?.amount;
    },
    insuranceCurrency (newCurrency) {
      this.form.currency = newCurrency
    },
    "form.countries": {
      deep: true,
      handler() {
        this.form.cover_value = null
      }
    },
    "fields.period": function(period) {
      this.form.end_date = period && this.form.start_date
          ? new Date(
              this.fields.beginDate.getTime() +
              period * 24 * 60 * 60 * 1000
          )
          : null;
    },
    "fields.start_date": function(beginDate) {
      this.form.end_date = this.form.period && beginDate
          ? new Date(
              beginDate.getTime() +
              this.fields.period * 24 * 60 * 60 * 1000
          )
          : null;
    },
  },
  methods: {
    async checkHolder() {
      this.form.holder.checked = true;
      this.form.holder.checkMessage = null;

      try {
        await window.axios.post('/verified-client-ps', this.form.holder)
      } catch (e) {
        const error = {
          message: e?.response?.data?.message || 'Ошибка при сверке клиента',
          code: e?.response?.data?.error_code || 0,
          title: e?.response?.data?.message || 'Ошибка при сверке клиента',
        }
        this.form.holder.checked = false;
        this.form.holder.checkMessage = e?.response?.data?.message;

        this.onError(error)
      }
    },

    async checkTourists() {
      for (let i = 0; i < this.form.insureds.length; i++) {
        this.form.insureds[i].checked = true;
        this.form.insureds[i].checkMessage = null;

        try {
          await window.axios.post('/verified-client-ps', this.form.insureds[i])
        } catch (e) {
          const error = {
            message: e?.response?.data?.message || 'Ошибка при сверке клиента',
            code: e?.response?.data?.error_code || 0,
            title: e?.response?.data?.message || 'Ошибка при сверке клиента',
          }

          this.onError(error)

          this.form.insureds[i].checked = false;
          this.form.insureds[i].checkMessage = e?.response?.data?.message;
        }
      }

    },
    /**
     * Toggle multiple type of travel
    * */
    changeTravelType(travelType) {
      this.form.is_multiple = (travelType === 'true')
    },

    /**
     * Добавление в форму страну поездки
    * */
    addCountry(country) {
      this.form.countries.push(country)
      this.saveDraft();
      this.form.end_date = null;
      this.clearSelectedAmount();
    },

    /**
     * Удаление из формы страну поездки
     * */
    deleteCountry(index) {
      this.form.countries.splice(index, 1);
      this.form.end_date = null;
      this.clearSelectedAmount();
    },

    /**
     * Adding new object of tourist
     * */
    addTourist() {
      this.form.insureds.push({
        uuid: uuidv4(),
        individual_id_number: null,
        gender: null,
        doc_type: null,
        doc_number: null,
        doc_issue_date: null,
        address_region: null,
        address_state: null,
        address_city: null,
        address_street_full: null,
        birth_date: null,
        last_name: null,
        first_name: null,
        documents: [],
        country: null,
        tourist_type: 1,
        error:null,
        full_name: null,
        checked: false,
        checkMessage: null
      })
    },

    /**
     * Removing tourist
     * */
    removeTourist(index) {
      this.form.insureds.splice(index, 1);

      if(this.form.insureds.length === 0) {
        this.form.owner_is_holder = true
      }
    },

    /**
     * Change tourist by index
     * */
    async changeTourist({ item, index }) {
      this.form.insureds.splice(index, 1, item)
    },

    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result.toISOString();
    },

    /**
     * Обнуление сумм
     * */
    clearSelectedAmount() {
      this.form.cover_value = null;
      this.insuredSum = null;
    },

    /**
     * Валидация резидента
    * */

    validateResident (resident) {
      if(resident.individual_id_number && resident.individual_id_number.length !== 12) {
        return false
      }

      return !( !resident.gender ||
                !resident.doc_type ||
                !resident.doc_number ||
                !resident.doc_issue_date ||
                // !resident.birth_date ||
                !resident.address_region ||
                !resident.address_state ||
                !resident.address_city ||
                !resident.address_street_full);
    },
    validateForeigner (foreigner) {
      if (foreigner) {
        return !(!foreigner.doc_type ||
            !foreigner.doc_number ||
            !foreigner.doc_issue_date);

      }
      return true
    }
  },

  computed: {
    ...mapGetters({
      insuranceProduct: 'INSURANCE_PRODUCT'
    }),
    canAddTourist() {
      if(this.isSchengenArea) {
        return this.computedTourists.length < 1;
      } else {
        return this.computedTourists.length <= 4
      }
    },
    untilTomorrow(){
      return moment().add(1, "days").format("YYYY-MM-DD")
    },
    clientsChecked() {
      let checked = true;

      if (this.form.holder.checked === false) {
        checked = false;
      }

      this.form.insureds.forEach(tourist => {
        if (tourist.status === false) {
          checked = false
        }
      })

      return checked;
    },
    /**
     * Описание для видов поездок
    * */
    travelTypeAdditionText() {
      return this.travelIsMultiple ? this.$i18n.t('insuredIsEntitled') : null
    },

    /**
     * Описание для каждого пакета
     * */
    singleTravelTypeText() {
      return !this.travelIsMultiple && this.packet ? this.packets.find((item) => item.id === this.packet).description : '';
    },

    /**
    * Периоды поездок для каждого пакета
    * */
    insurancePeriods() {
      return this.travelIsMultiple ? this.multiple_insurance_periods : this.single_insurance_periods;
    },
    /**
     * Периоды поездок для каждого пакета
     * */
    touristInfoTitle() {
      return uuid => {
        const currentIndex = this.computedTourists.findIndex((item) => item.uuid === uuid)
        const order = currentIndex + 1;
        return this.$i18n.t(`tourist${order}info`)
      }
    },

    insuranceDurations() {
      switch (String(this.form.period)) {
        case '180':
          return [{ name: this.$i18n.t('upTo45'), value: 45 }];
        case '90':
          return [{ name: this.$i18n.t('upTo30'), value: 30 }];
        case '30':
          return [{ name: this.$i18n.t('upTo10'), value: 10 }];
        default:
          return this.travelDurations;
      }
    },

    countryAddBtnDisabled() { return this.form.countries.length >= 3 },

    computedTourists() {
      const tourists = [];

      if (this.form.owner_is_holder) {
        tourists.push(this.form.holder)
      }

      this.form.insureds.forEach(item => tourists.push(item))

      return tourists;
    },

    /**
     * Актуальные пакеты для одноразовой поездки
     * */
    packets() {
      const {programs} = this.travelerDictionary;

      return programs && programs.length
          ? programs.map((p) => ({name: p.title, value: p.id, ...p})) : [];
    },

    /**
     * Список стран для поездки (id, code, region)
     * */
    countriesData() {
      return this.travelerDictionary?.countries || [];
    },

    /**
     * Отфильтрованный список стран для поездки
     * */
    remainingCountries() {
      const { countries: selected } = this.form

      const { countriesData, insuranceCurrency, isSchengenArea } = this

      const result = selected.length
          ? countriesData.filter((country) => {
            return (
                !selected.some((sc) => sc.id === country.id) &&
                ((!insuranceCurrency || country.currency === insuranceCurrency) &&
                    (isSchengenArea === null || isSchengenArea === (country.region === "Шенген")))
            );
          })
          : countriesData;

      return result.map(c => ({
        name: `${c.title} ${c.region === 'Шенген' ? ('(Шенген)') : ''}`,
        value: c.id,
        region: c.region,
        code: c.code,
        currency: c.currency,
        id: c.id,
        group_number: c.group_number
      }))
    },

    travelIsMultiple() {
      return this.form.is_multiple
    },

    /**
     * Поездка в стран шенген
     * */
    isSchengenArea() {
      const [firstCountry] = this.form.countries;

      if(!firstCountry) { return }

      return firstCountry.region === 'Шенген'
    },

    /**
     * Валюта первой выбранной страны
     * */
    insuranceCurrency () {
      const [firstCountry] = this.form.countries;

      if(!firstCountry) { return }

      return firstCountry?.currency
    },

    /**
     * Максимальная группа риска стран
     * */
    maxGroupCountry () {
      const { countries: selectedOnes } = this.form;

      if(selectedOnes.length === 0) { return }

      return selectedOnes.reduce((prev, current) => (prev.country_group > current.country_group) ? prev : current)
    },

    /**
     * Получение суммы покрытии для определенных видов
    * */
    amounts() {
      const { form, travelerDictionary, maxGroupCountry } = this
      const { countries } = form;
      const { amounts } = travelerDictionary;

      if (!countries.length) {
        return null;
      }

      const { group_number, currency } = maxGroupCountry;

      if (amounts && amounts.length) {
        let computed =  amounts
            .filter(a => a.country_group === group_number && a.travel_program_id === this.packet)
            .map((p) => ({ name: `${p.amount} ${currency}`, value: p.id, amount: p.amount, country_group: p.country_group, id: p.id }))
            .sort((a, b) => a.amount - b.amount);

        if(computed.length === 1) {
          this.insuredSum = computed[0]
        }

        return computed
      }
      return null;
    },

    /**
     * Начальная дата окончания поездки
     * */
    minEndDate() {
      if (this.form.start_date) {
        return moment(this.form.start_date).add(1, 'day').format('YYYY-MM-DD')
      } else {
        return moment().format('YYYY-MM-DD');
      }
    },
  }
}