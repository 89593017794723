<template>
  <ValidationObserver ref="form" v-slot="{ invalid, errors }">
    <input-field
        :label="$t('enterPhoneNumber')"
        :value="phone_number"
        field-type="tel"
        :is-block="true"
        rules="required|min:10"
        name="phone"
        mask="+7 (###) ###-##-##"
        :disabled="true"
    />

    <div v-show="phone_number && errors.phone && errors.phone.length === 0">
      <select-date
          :is-block="true"
          rules="required"
          name="start_date"
          :value="startDate"
          @input="changeStartDate"
          :until="initialDate"
          :title="$t('policyStartDate')"
          :is-disabled-before-today="true"
      />

      <PersonInfo v-if="holder" :value="holder" @changed="changeHolder" :key="holder.uuid" @error="onError" rules="required"/>

      <search-select
          :options="prices"
          :placeholder="$t('premium')"
          label="name"
          :reduce="price => price.reward"
          :value="reward"
          :is-block="true"
          @input="changeReward"
          :addition="$t('cascoPriceLimit', {reward})"
      />

    </div>

    <template v-for="(insured, index) in insureds">
      <PersonInfo
          :value="insured"
          :index="index"
          :key="`insured_${insured.uuid}`"
          :can-delete="!!insureds.length"
          @removeItem="removeInsured"
          @changed="changeInsured"
          @error="onError"
          rules="required"
      />
    </template>

    <add-person-button v-if="insureds.length < 2" @click.native="addNewInsured"/>

    <car-info
        :value="vehicle"
        :key="vehicle.uuid"
        @changed="changeVehicle"
        @error="onError"
        :available-types="[1]"
        :can-be-selected-cars="canBeSelectedCars"
    />
    <box-product-bonuses :available-products="availableBonuses" v-model="bonusProduct"/>

    <div class="final-amount-wrapper">
      <final-amount
          :bonus-title="bonusTitle"
          :bonus-value="bonusValue"
          :amount="finalAmount"
          :invalid="invalid"
          :fix-sum="finalAmount"
          @submit="onSubmit"
      />
    </div>

  </ValidationObserver>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {cascoLiteCalculation } from "../../api/calculations";
import InputField from "@/components/BuyPolicy/InputField";
import SelectDate from "../BuyPolicy/SelectDate";
import AddPersonButton from "../BuyPolicy/AddPersonButton";
import FinalAmount from "../BuyPolicy/FinalAmount";
import SearchSelect from "../BuyPolicy/SearchSelect";
import eventLogger from '../../services/eventLogger';
import PersonInfo from "../PersonInfo";
import CarInfo from "../CarInfo";
import ePayPayment from "../../mixin/ePayPayment";
import axios from "axios";
import moment from "moment";
import {uuidv4} from "../../plugins/Helpers";
import commonFormData from "../../mixin/commonFormData";
import redmine from "../../mixin/redmine";
import BoxProductBonuses from "../BuyPolicy/BoxProductBonuses";
import SelectRate from "../BuyPolicy/SelectRate";

export default {
  name: "CascoLiteForm",
  mixins: [ePayPayment, commonFormData, redmine],
  components: {
    BoxProductBonuses,
    CarInfo,
    PersonInfo,
    AddPersonButton,
    InputField,
    SelectDate,
    FinalAmount,
    SearchSelect,
    SelectRate
  },
  data() {
    return {
      prices: [
        {name: '6000 тенге', reward: 100000, value: 6000},
        {name: '12000 тенге', reward: 200000, value: 12000},
        {name: '18000 тенге', reward: 300000, value: 18000},
        {name: '30000 тенге', reward: 500000, value: 30000}
      ],
      amount: 0,
    }
  },
  computed: {
    ...mapGetters({
      reward: 'cascoForm/reward',
      holder: 'cascoForm/holder',
      startDate: 'cascoForm/startDate',
      insureds: 'cascoForm/insureds',
      vehicle: 'cascoForm/vehicle',
      form: 'cascoForm/finalFormValue',
      availableCars: 'ogpoForm/availableCars',
    }),
    clientsChecked(){
      let checked = true;

      if(this.holder.checked === false) {
        checked = false
      }

      this.insureds.forEach(client => {
        if(client.checked === false) { checked = false }
      })

      return checked
    },
    finalAmount() {
      const { reward } = this

      const price = this.prices.find(item => item.reward === reward);

      if(!price) { return 0 }

      return price.value
    },

    bonusProduct: {
      get() {
        return this.$store.getters['cascoForm/bonusId'];
      },
      set(payload) {
        this.changeSelectedBonus(payload)
      }
    },
    canBeSelectedCars() {
      return this.availableCars.filter( ( el ) => el !== this.form.vehicle.license_plate );
    },
  },

  async created() {
    eventLogger('view_ogpo_screen', {product_type: 2221})

    await this.fetchAvailableCars(this.authUser.individual_id_number)
  },

  methods: {
    ...mapActions({
      changeReward: 'cascoForm/changeReward',
      changeStartDate: 'cascoForm/changeStartDate',
      getPersonInfo: 'cascoForm/getPersonInfo',
      changeHolder: 'cascoForm/changeHolder',
      addNewInsured: 'cascoForm/addNewInsured',
      changeInsured: 'cascoForm/changeInsured',
      removeInsured: 'cascoForm/removeInsured',
      changeVehicle: 'cascoForm/changeVehicle',
      addAgreementInsured: 'cascoForm/addInsured',
      resetForm: 'cascoForm/resetForm',
      verifyHolder: 'cascoForm/verifyHolder',
      changeSelectedBonus: 'cascoForm/changeSelectedBonus',
      fetchAvailableCars: 'ogpoForm/fetchAvailableCars'
    }),

    /**
    * Инициализация начальных данных для продления
    * */
    async setAgreementInfo() {
        this.agreement_id = this.$route.query.agreement_id;

        const { data } = await axios.get(`policies/${this.agreement_id}`);

        this.setInitialDate(data.endDate.date)

        this.$store.commit("cascoForm/CHANGE_HOLDER", {
          uuid: uuidv4(),
          individual_id_number: data.holder.bin,
        });

        this.$store.commit('cascoForm/RESET_INSUREDS');

        data.insureds
          .filter(item => item.bin !== data.holder.bin)
          .map((item) => {
            const defaultDoc = item.documents.find(doc => doc.doc_type === 'IC') || item.documents[0];
            const defaultAddress = item.addresses.find(address => address.address_type === 'H') || item.addresses[0];
            const docDate = defaultDoc?.doc_issue_date?.date ? moment(defaultDoc.doc_issue_date.date) : moment();
            this.$store.commit('cascoForm/ADD_INSURED', {
              uuid: uuidv4(),
              individual_id_number: item.bin,
              gender: item.sex,
              doc_type: defaultDoc?.doc_type,
              doc_number: defaultDoc?.doc_number,
              doc_issue_date: docDate.format('YYYY-MM-DD'),
              address_region: defaultAddress?.address_region,
              address_state: defaultAddress?.address_state,
              address_city: defaultAddress?.address_city,
              address_street_full: defaultAddress?.address_street,
              address_street_no: defaultAddress?.address_street_no,
              address_flat_no: defaultAddress?.address_flat_no,
            })
          })

        const [vehicle] = data.objects;

        this.$store.commit("cascoForm/CHANGE_VEHICLE", vehicle)
    },

    /**
    * Инициализация начальных данных
    * */
    async setDefaultInfo(){
      const { authUser,  holder } = this;

      // Если нет держателя то записываем текущего пользователя
      if(holder === null || !this.holder.individual_id_number) {
        this.$store.commit("cascoForm/CHANGE_HOLDER", {
          uuid: uuidv4(),
          individual_id_number: authUser.individual_id_number
        })
      }
      this.setInitialDate(moment().add(1, 'days'))

    },

    /**
    * Отправляем форму на сервер для калькуляции полиса
    * */
    async calculation() {
      const data = await cascoLiteCalculation(this.form)

      // Логирование
      eventLogger('select_payment_finance', {payment_sum: this.amount})

      return data
    },

    /**
     * Сверка клиентов перед отправкой
     * */
    async checkClients() {
      try{
        await this.verifyHolder()
      } catch (e) {
        this.onError(e)
      }

      for (let index = 0; index < this.insureds.length; index++) {
        let item = this.insureds[index];

        item.checkMessage = null

        try {
          await axios.post(`/verified-client-dl`, this.insureds[index])

          item.checked = true
        }catch (e) {
          this.onError({
            message: e?.response?.data?.message || 'Ошибка при сверке клиента',
            code: e?.response?.data?.error_code || 0,
            title: e?.response?.data?.message || 'Ошибка при сверке клиента',
          })

          item.checkMessage = e?.response?.data?.message
          item.checked = false
        }

        this.changeInsured({ item, index })
      }
    }
  }
}
</script>
