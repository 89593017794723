<template>
  <ValidationObserver ref="form" v-slot="{ invalid, errors }">
    <input-field
        :label="$t('enterPhoneNumber')"
        :value="phone_number"
        field-type="tel"
        :is-block="true"
        rules="required|min:10"
        name="phone"
        mask="+7 (###) ###-##-##"
        :disabled="true"
    />

    <div v-show="phone_number && errors.phone && errors.phone.length === 0">
<!--      <select-date-->
<!--          :is-block="true"-->
<!--          rules="required"-->
<!--          name="start_date"-->
<!--          :value="startDate"-->
<!--          @input="changeStartDate"-->
<!--          :until="initialDate"-->
<!--          :title="$t('policyStartDate')"-->
<!--          :is-disabled-before-today="true"-->
<!--      />-->

      <PersonInfo v-if="holder" :value="holder" @changed="changeSubsHolder" :key="holder.uuid" @error="onError" rules="required"/>

     <!--  <search-select
          :options="prices"
          :placeholder="$t('premium')"
          label="name"
          :reduce="price => price.reward"
          :value="reward"
          :is-block="true"
          @input="changeReward"
          :addition="$t('cascoPriceLimit', {reward})"
      /> -->

    </div>

    <template v-for="(insured, index) in insureds">
      <PersonInfo
          :value="insured"
          :index="index"
          :key="`insured_${insured.uuid}`"
          :can-delete="!!insureds.length"
          @removeItem="removeInsured"
          @changed="changeInsured"
          @error="onError"
          rules="required"
      />
    </template>

    <add-person-button v-if="insureds.length < 2" @click.native="addNewInsured"/>

    <car-info :value="vehicle" :key="vehicle.uuid" @changed="changeVehicle" @error="onError" :available-types="[1]" :can-be-selected-cars="canBeSelectedCars"/>

    <!--  <select-rate
       :title="'Тарифы'"
       :rates="rates"
       @change_input="changeRate"/> -->

    <box-product-bonuses :available-products="availableBonuses" v-model="bonusProduct"/>

    <template v-if="loadingActive">
        <div class="image-card card py-4 px-3" v-for="item in 1" :key="item" style="margin-bottom: 16px;">
          <div class="">
            <h3 class="title skeleton skeleton-text w-75"></h3>
            <h3 class="title skeleton skeleton-text w-50"></h3>
            <h3 class="title skeleton skeleton-text w-25"></h3>
            <div class="gray-btn skeleton w-50"></div>
          </div>
        </div>
      </template>

    <select-tariff v-else :tariffs="tariffs" :title="$t('rate')" @change_input="changeTariffCalc"></select-tariff>




    <div class="final-amount-wrapper">
      <final-amount
              :amount="finalAmountObj.amount"
              :discount-value="finalAmountObj.bonus_value"
              :fix-sum="finalAmountObj.fix_sum"
              :rate-total="true"
              :invalid="invalid"
              :is-sub="true"
              @submit="onSubmit"
          />
    </div>

  </ValidationObserver>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {cascoLiteSubscriptionCalculation } from "../../api/calculations";
import InputField from "@/components/BuyPolicy/InputField";
import SelectDate from "../BuyPolicy/SelectDate";
import AddPersonButton from "../BuyPolicy/AddPersonButton";
import FinalAmount from "../BuyPolicy/FinalAmount";
import SearchSelect from "../BuyPolicy/SearchSelect";
import eventLogger from '../../services/eventLogger';
import PersonInfo from "../PersonInfo";
import CarInfo from "../CarInfo";
import ePayPayment from "../../mixin/ePayPayment";
import axios from "axios";
import moment from "moment";
import {uuidv4} from "../../plugins/Helpers";
import commonFormData from "../../mixin/commonFormData";
import redmine from "../../mixin/redmine";
import BoxProductBonuses from "../BuyPolicy/BoxProductBonuses";
import SelectRate from "../BuyPolicy/SelectRate";
import SelectTariff from "@/components/BuyPolicy/SelectTariff";
import subscriptionForm from "../../mixin/subscriptionForm";

export default {
  name: "CascoLiteForm",
  mixins: [ePayPayment, commonFormData, redmine, subscriptionForm],
  components: {
    BoxProductBonuses,
    CarInfo,
    PersonInfo,
    AddPersonButton,
    InputField,
    SelectDate,
    FinalAmount,
    SearchSelect,
    SelectRate,
    SelectTariff
  },
  mounted(){
    //this.changeRate(this.rates_index);
    this.getFullTariff();
  },
  data() {
    return {
      tariffUrl: 'subscription/casco-tariffs',
      loadingActive: true,
      rates_index: 0,
      currentTariff: '',
      rates: [
        {name: 'Легкий', price: 690, lists: [{ name: 'Выплата Вам', price: '200 000' }] },
        {name: 'Стандартный', price: 990, lists: [{ name: 'Выплата Вам', price: '300 000' }] },
        {name: 'Оптимальный', price: 1290, lists: [{ name: 'Выплата Вам', price: '400 000' }] },
      ],
      rates_calc: [
        {amount: 2070, bonus_title: 'Скидка на первые три месяца', bonus_value: 1500, total_title: 'Итого  на три месяца',fix_sum: 570, product_name: 'за три месяца', label_id: 'LIGHT' },
        {amount: 2970, bonus_title: 'Скидка на первые три месяца', bonus_value: 2100, total_title: 'Итого  на три месяца',fix_sum: 870, product_name: 'за три месяца', label_id: 'STANDARD'  },
        {amount: 3870, bonus_title: 'Скидка на первые три месяца', bonus_value: 2700, total_title: 'Итого  на три месяца',fix_sum: 1170, product_name: 'за три месяца',  label_id: 'OPTY'},
      ],
      fixedTariffs: null,
      finalAmountObj: {},
      amount: 0,
      tariffs: [],
    }
  },

  computed: {
    ...mapGetters({
      authUser: 'authUser',
      reward: 'cascoSubs/reward',
      holder: 'cascoSubs/holder',
      startDate: 'cascoSubs/startDate',
      insureds: 'cascoSubs/insureds',
      vehicle: 'cascoSubs/vehicle',
      form: 'cascoSubs/finalSubscriptionFormValue',
      manId: 'cascoSubs/manId',
      availableCars: 'ogpoForm/availableCars',
    }),
    clientsChecked() {
      let checked = true;

      if(this.holder.checked === false) {
        checked = false
      }

      this.insureds.forEach(client => {
        if(client.checked === false) { checked = false }
      })

      return checked
    },

    bonusProduct: {
      get() {
        return this.$store.getters['cascoSubs/bonusId'];
      },
      set(payload) {
        this.changeSelectedBonus(payload)
      }
    },

    canBeSelectedCars() {
      return this.availableCars.filter( ( el ) => el !== this.form.vehicle.license_plate );
    },
  },

  async created() {
    await eventLogger('view_kasko_screen_subscription', { product_type: 2225 });

    await this.fetchAvailableCars(this.authUser.individual_id_number)

    this.changeManId(this.authUser.id);
    this.changeTariff('Легкий');
  },

  methods: {
    ...mapActions({
      changeReward: 'cascoSubs/changeReward',
      changeStartDate: 'cascoSubs/changeStartDate',
      getPersonInfo: 'cascoSubs/getPersonInfo',
      changeHolder: 'cascoSubs/changeHolder',
      addNewInsured: 'cascoSubs/addNewInsured',
      changeInsured: 'cascoSubs/changeInsured',
      removeInsured: 'cascoSubs/removeInsured',
      changeVehicle: 'cascoSubs/changeVehicle',
      addAgreementInsured: 'cascoSubs/addInsured',
      resetForm: 'cascoSubs/resetForm',
      verifyHolder: 'cascoSubs/verifyHolder',
      changeSelectedBonus: 'cascoSubs/changeSelectedBonus',
      changeTariff: 'cascoSubs/changeTariff',
      changeManId: 'cascoSubs/changeManId',
      fetchAvailableCars: 'ogpoForm/fetchAvailableCars'
    }),

    /**
    * Инициализация начальных данных для продления
    * */
    async setAgreementInfo() {
        this.agreement_id = this.$route.query.agreement_id;

        const { data } = await axios.get(`policies/${this.agreement_id}`);

        this.setInitialDate(data.endDate.date)

        this.$store.commit("cascoSubs/CHANGE_HOLDER", {
          uuid: uuidv4(),
          individual_id_number: data.holder.bin,
        });

        this.$store.commit('cascoSubs/RESET_INSUREDS');

        data.insureds
          .filter(item => item.bin !== data.holder.bin)
          .map((item) => {
            const defaultDoc = item.documents.find(doc => doc.doc_type === 'IC') || item.documents[0];
            const defaultAddress = item.addresses.find(address => address.address_type === 'H') || item.addresses[0];
            const docDate = defaultDoc?.doc_issue_date?.date ? moment(defaultDoc.doc_issue_date.date) : moment();
            this.$store.commit('cascoSubs/ADD_INSURED', {
              uuid: uuidv4(),
              individual_id_number: item.bin,
              gender: item.sex,
              doc_type: defaultDoc?.doc_type,
              doc_number: defaultDoc?.doc_number,
              doc_issue_date: docDate.format('YYYY-MM-DD'),
              address_region: defaultAddress?.address_region,
              address_state: defaultAddress?.address_state,
              address_city: defaultAddress?.address_city,
              address_street_full: defaultAddress?.address_street,
              address_street_no: defaultAddress?.address_street_no,
              address_flat_no: defaultAddress?.address_flat_no,
            })
          })

        const [vehicle] = data.objects;

        this.$store.commit("cascoSubs/CHANGE_VEHICLE", vehicle)
    },

    /**
    * Инициализация начальных данных
    * */
    async setDefaultInfo(){
      const { authUser,  holder } = this;

      // Если нет держателя то записываем текущего пользователя
      if(holder === null || !this.holder.individual_id_number) {
        this.$store.commit("cascoSubs/CHANGE_HOLDER", {
          uuid: uuidv4(),
          individual_id_number: authUser.individual_id_number
        })
      }

      this.setInitialDate(moment().add(1, "days").format("YYYY-MM-DD"))
    },

    /**
    * Отправляем форму на сервер для калькуляции полиса
    * */
    async calculation() {
      const data = await cascoLiteSubscriptionCalculation(this.form)

      // Логирование
      await eventLogger('select_payment_kasko_subscription', {payment_sum: this.amount})
      return data
    },

    /**
     * Сверка клиентов перед отправкой
     * */
    async checkClients() {
      try{
        await this.verifyHolder()
      } catch (e) {
        this.onError(e)
      }

      for (let index = 0; index < this.insureds.length; index++) {
        let item = this.insureds[index];

        item.checkMessage = null

        try {
          await axios.post(`/verified-client-dl`, this.insureds[index])

          item.checked = true
        }catch (e) {
          this.onError({
            message: e?.response?.data?.message || 'Ошибка при сверке клиента',
            code: e?.response?.data?.error_code || 0,
            title: e?.response?.data?.message || 'Ошибка при сверке клиента',
          })

          item.checkMessage = e?.response?.data?.message
          item.checked = false
        }

        this.changeInsured({ item, index })
      }
    }
  },
}
</script>
